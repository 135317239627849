// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react'
import { Image } from 'primereact/image'
import { useDispatch, useSelector } from 'react-redux'
import { getThumbBlobName} from '@/utils'
import { RootState, setSelectedImage, setReviewImageThumbs, setReviewMainImageLoading, setHasThumbClick, setSelectedQuestionId } from '@/store'
import { Skeleton } from 'primereact/skeleton'
import useStorageAccess from '@/hooks/useStorageAccess'
import { useAppSelector } from '@/store'

interface ReviewThumbsProps {
    onMultipleRows?: (hasMultipleRows: boolean) => void;
}

export default function ReviewThumbs({ onMultipleRows }: ReviewThumbsProps) {

    const dispatch = useDispatch()
    const storageAccess = useStorageAccess()
    const {
        reviewData,
        selectedTable,
        selectedImage,
        reviewImageThumbs
    } = useSelector((state: RootState) => state.review)


    const questionValues = useAppSelector((state) => state.review.questionValues)
    const detectionQuestions = useAppSelector((state) => state.review.detectionQuestions)

    const [isLoading, setIsLoading] = useState(true)
    const containerRef = useRef(null)
    const [containerHeight, setContainerHeight] = useState(0)
    const [showPartialSecondRow, setShowPartialSecondRow] = useState(false)
    const [thumbHeight, setThumbHeight] = useState(100) // Default height
    
    /**
     * Blob URL create
     */
    const getBlobUrl = (blobName: string) => {
        console.log('storageAccess', storageAccess)
        if (!storageAccess.token) return null;
        const { storageName, token } = storageAccess;
        return token.replace('/?', `/${storageName}/${blobName}?`);
    }

    // Calculate container dimensions and check if content overflows
    useEffect(() => {
        if (!containerRef.current || isLoading || !reviewImageThumbs.length) return;

        const calculateDimensions = () => {
            const container = containerRef.current;
            if (!container) return;

            // Get container width
            const containerWidth = container.clientWidth;
            
            // Calculate how many thumbnails fit in a row (based on grid-cols-6 and gap-1.5)
            const gap = 6; // 1.5rem = ~6px
            const thumbsPerRow = 6; // from grid-cols-6
            const availableWidth = containerWidth - (gap * (thumbsPerRow - 1));
            const thumbWidth = availableWidth / thumbsPerRow;
            
            // Calculate optimal thumb height based on aspect ratio (usually 16:9 or 4:3)
            // Using 3:2 as a common aspect ratio for thumbnails
            const optimalThumbHeight = Math.floor(thumbWidth * (2/3));
            
            // Set minimum and maximum height constraints
            const newThumbHeight = Math.max(70, Math.min(100, optimalThumbHeight));
            setThumbHeight(newThumbHeight);
            
            // Calculate total rows needed
            const totalRows = Math.ceil(reviewImageThumbs.length / thumbsPerRow);
            
            // Check if we need to show partial second row
            const hasMultipleRows = totalRows > 1;
            setShowPartialSecondRow(hasMultipleRows);
            
            // Notify parent component about multiple rows
            if (onMultipleRows) {
                onMultipleRows(hasMultipleRows);
            }
            
            // If only one row, set container height to match thumb height
            // If multiple rows, allow natural flow for scrolling
            if (totalRows <= 1) {
                setContainerHeight(newThumbHeight);
            } else {
                setContainerHeight(0); // Reset container height to allow natural flow
            }
        };

        // Initial calculation
        calculateDimensions();
        
        // Recalculate on window resize
        window.addEventListener('resize', calculateDimensions);
        
        return () => {
            window.removeEventListener('resize', calculateDimensions);
        };
    }, [reviewImageThumbs, isLoading, onMultipleRows]);

    useEffect(() => {
        setIsLoading(true)
        if (reviewData?.assetDetail && reviewData?.assetDetail?.asset?.id) {
            const filterIns = reviewData.assetDetail.inspections?.find(
                (val) => val.assetId === reviewData?.assetDetail?.asset?.id
            );

            if (filterIns?.images) {
                const imageUrls = filterIns?.images?.map(image => ({
                    blobName: image.blobName,
                    url: getBlobUrl(getThumbBlobName(image.blobName)),
                    imageId: image.id
                }))
                dispatch(setHasThumbClick(true))
                dispatch(setSelectedQuestionId(null))
                dispatch(setReviewImageThumbs(imageUrls))
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }
        return () => {
            setReviewImageThumbs([]);
            setIsLoading(false)
        }
    }, [reviewData, selectedTable, storageAccess])
    
    const handleImageSelect = (index, inspectionId) => {
        dispatch(setSelectedImage({
            index,
            inspectionId
        }));
    };
    return (
        <>
            <div 
                ref={containerRef}
                className="grid grid-cols-6 gap-1.5 w-full"
                style={{ 
                    height: containerHeight ? `${containerHeight}px` : 'auto',
                    // Only add padding-bottom if we have multiple rows
                    paddingBottom: showPartialSecondRow ? '20px' : '0'
                }}
            >
                {isLoading && (
                    <div className="col-span-7 gap-2 flex flex-row w-full">
                        {Array.from({ length: 5 }, (_, index) => (
                            <Skeleton
                                key={`skeleton-${index}`}
                                width="100vw"
                                height={`${thumbHeight}px`}
                                borderRadius="16px"
                            />
                        ))}
                    </div>
                )}
                {!isLoading && storageAccess.token &&
                    reviewImageThumbs.map((image, index) => {
                        
                        
                        const allImageIds = reviewData?.assetDetail?.detectionQuestions?.reduce((acc, question) => {
                            return [...acc, ...(question.imageIds?.length > 0 ? [question.imageIds[0]] : [])]
                        }, []) || []
                        
                        const isGeminiImage = allImageIds.some(id => 
                            image.imageId === id
                        )
                        
                        return (
                            <div
                                key={image.blobName}
                                className={`relative cursor-pointer ${selectedImage === index ? 'selected-border' : ''}`}
                                style={{ height: `${thumbHeight}px` }}
                                onClick={() => {
                                    if (selectedImage !== index) {
                                        dispatch(setReviewMainImageLoading(true));
                                        dispatch(setSelectedImage({ index, reviewData, selectedTable }))
                                        dispatch(setHasThumbClick(true))
                                        dispatch(setSelectedQuestionId(null))
                                    }
                                }}
                            >
                                {isGeminiImage && (
                                     <div
                                     className="flex absolute top-1 right-[2px] z-999 justify-center min-w-6 max-w-6 min-h-6 max-h-6 rounded-full roundend-xl items-center content-center"
                                 >
                                    <img
                                        src="/img/gemini-icon-2.png"
                                        className="flex justify-center items-center"
                                        style={{ 
                                            width: '20px', 
                                            height: '20px', 
                                            overflow: 'visible'
                                        }}
                                        alt="gemini-mark"
                                    />
                                    </div>
                                )}
                                <div className="absolute bottom-1 right-1 bg-black bg-opacity-50 text-white rounded-md px-1.5 py-0.5 text-xs font-medium">
                                    {index + 1}
                                </div>
                                <Image
                                    src={image?.url}
                                    alt="Thumb"
                                    imageClassName="w-full object-cover rounded h-full"
                                />
                            </div>
                        );
                    })}
            </div>
        </>
    )
}
