// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { Button, ImageView } from '@/components/ui'
import { Skeleton } from 'primereact/skeleton'
import {
    getReviewImages,
    getTransmissionStructure,
    RootState,
    setReviewMainImage,
} from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import ReviewThumbs from './ReviewThumbs'
import ImageComment from './Comment/ImageComment'
import { Image } from 'primereact/image'
import useStorageAccess from '@/hooks/useStorageAccess'
import { HiOutlineThumbDown, HiOutlineThumbUp, HiZoomIn, HiZoomOut } from 'react-icons/hi'
import { MdClose, MdFullscreen, MdFullscreenExit } from 'react-icons/md'
import BaseService from '@/services/BaseService'
import type { AxiosResponse } from 'axios'
import { IoClose } from 'react-icons/io5'
import { getPreviewBlobName } from "@/utils";
import { Image as PrimeReactImage } from 'primereact/image';
import { FeedbackButtons } from './FeedbackButtons'
import { IoLayersSharp } from "react-icons/io5";
import { InputSwitch } from 'primereact/inputswitch';
import { MdOutlineOpacity } from "react-icons/md";
import { Slider } from 'primereact/slider';
import { setReviewMainImageLoading } from '@/store/slices/dashboard/reviewSlice';
import { Tooltip } from 'primereact/tooltip';
import { FiSettings } from 'react-icons/fi';
import { TbAdjustmentsHorizontal } from 'react-icons/tb';
import { IoChevronBackOutline } from 'react-icons/io5';

// Color palette for transmission elements
const TransmissionColors = [
    [220, 38, 38, 200],    // deep red
    [22, 163, 74, 200],    // forest green
    [37, 99, 235, 200],    // royal blue
    [234, 88, 12, 200],    // burnt orange
    [49, 46, 129, 200],    // deep navy
    [219, 39, 119, 200],   // hot pink
    [15, 118, 110, 200],   // deep teal
    [124, 58, 237, 200],   // deep indigo
    [243, 156, 18, 200],    // turquoise
    [153, 0, 153, 200],    // magenta
    [13, 148, 136, 200],   // golden yellow
    [49, 46, 129, 200],     // black
    [255, 0, 255, 200]     // fusha
];

const TransmissionOptions = [
    { label: 'Pole: Monopole', value: 'Pole: Monopole' },
    { label: 'Pole: H-Frame', value: 'Pole: H-Frame' },
    { label: 'Pole: Lattice', value: 'Pole: Lattice' },
    { label: 'Insulator', value: 'Insulator' },
    { label: 'Cross Arm', value: 'Cross Arm' },
    { label: 'Transformer', value: 'Transformer' },
    { label: 'Arrestor', value: 'Arrestor' },
    { label: 'Ground Wire', value: 'Ground Wire' },
    { label: 'Guy Wire', value: 'Guy Wire' },
    { label: 'Conductor', value: 'Conductor' },
    { label: 'Static Wire', value: 'Static Wire' },
    { label: 'Steel Lattice', value: 'm_pole_silver_steel' },
    { label: 'Wooden Monopole', value: 'm_pole_wood' },
    { label: 'Weathered Monopole', value: 'm_pole_weathered_steel' },
    { label: 'Steel Monopole', value: 'hf_silver_steel' },
    { label: 'Wooden H-Frame', value: 'hf_wood' },
    { label: 'Steel Monopole', value: 'm_pole_silver_steel' },
    { label: 'Steel Lattice', value: 'lattice' },
    { label: 'Steel H-Frame', value: 'hf_steel' },
    { label: 'Triplex Wire', value: 'triplex_wire', color: TransmissionColors[0] },
    { label: 'Cutout', value: 'cutout', color: TransmissionColors[1] },
    { label: 'Cutout Drop', value: 'cutout_drop', color: TransmissionColors[12] },
    { label: 'Transformer', value: 'transformer', color: TransmissionColors[2] },
    { label: 'Monopole Wood', value: 'monopole_wood', color: TransmissionColors[3] },
    { label: 'Transformer Drop', value: 'transformer_drop', color: TransmissionColors[4] },
    { label: 'Secondary Rack', value: 'secondary_rack', color: TransmissionColors[5] },
    { label: 'Primary Rack', value: 'primary_rack', color: TransmissionColors[6] },
    { label: 'Riser', value: 'riser', color: TransmissionColors[7] },
    { label: 'Wire', value: 'wire', color: TransmissionColors[8] },
    { label: 'Weatherhead', value: 'weatherhead', color: TransmissionColors[9] },
]

// color assignment function by output
const getColorForOutput = (outputIndex: number) => {
    return TransmissionColors[outputIndex % TransmissionColors.length];
};

const rleFromString = (encodedString) => {
    const characterCodes = encodedString
        .split('')
        .map((char) => char.charCodeAt(0))

    let codePointer = 0
    let ith = 0
    let count = 0
    let more = 0

    const counts = []

    while (characterCodes[codePointer]) {
        count = 0
        ith = 0
        more = 1

        while (more) {
            const char = characterCodes[codePointer] - 48
            count |= (char & 0x1f) << (5 * ith)
            more = char & 0x20
            codePointer++
            ith++
            if (!more && char & 0x10) count |= -1 << (5 * ith)
        }

        if (counts.length > 2) {
            count += counts[counts.length - 2]
        }
        counts.push(count)
    }

    return counts
}

function decodeCocoRLE([rows, cols], counts, flat = true) {
    let pixelPosition = 0,
        binaryMask

    if (flat) {
        binaryMask = Array(rows * cols).fill(0)
    } else {
        binaryMask = Array.from({ length: rows }, (_) => Array(cols).fill(0))
    }

    for (let i = 0, rleLength = counts.length; i < rleLength; i += 2) {
        let zeros = counts[i],
            ones = counts[i + 1] ?? 0

        pixelPosition += zeros

        while (ones > 0) {
            const rowIndex = pixelPosition % rows,
                colIndex = (pixelPosition - rowIndex) / rows

            if (flat) {
                const arrayIndex = rowIndex * cols + colIndex
                binaryMask[arrayIndex] = 1
            } else {
                binaryMask[rowIndex][colIndex] = 1
            }

            pixelPosition++
            ones--
        }
    }

    if (!flat) {
        console.log('Result matrix:')
        binaryMask.forEach((row, i) => console.log(row.join(' '), `- row ${i}`))
    }

    return binaryMask
}

const waitForImageLoad = (image: HTMLImageElement): Promise<void> => {
    return new Promise((resolve) => {
        if (image.complete) {
            resolve();
        } else {
            image.onload = () => resolve();
        }
    });
};

const transmissionLabelMap = TransmissionOptions.reduce((acc, curr) => {
    acc[curr.value] = curr.label
    return acc
}, {})

const transmissionColorMap = TransmissionOptions.reduce((acc, curr) => {
    acc[curr.value] = curr.color
    return acc
}, {})

/**
 * Converts RLE (Run Length Encoding) data to a binary mask array
 * @param {number[]} rle - Array containing RLE encoded data
 * @param {number} width - Width of the mask
 * @param {number} height - Height of the mask
 * @returns {number[]} Binary mask array
 */
function rleToMask(rle: number[], width: number, height: number) {
    let mask = new Array(width * height).fill(0);

    for (let i = 0; i < rle.length; i += 2) {
        let start = rle[i];
        let length = rle[i + 1];
        for (let j = 0; j < length; j++) {
            mask[start + j] = 1;
        }
    }

    return mask;
}

/**
 * Calculates the centroid of a segmented area
 * @param {number[]} counts - RLE encoded mask data
 * @param {number} width - Width of the image
 * @param {number} height - Height of the image
 * @returns {Object|null} Centroid position and label placement information
 */
function getSegmentationCentroid(counts: number[], width: number, height: number) {
    // Convert RLE to mask
    let mask = rleToMask(counts, width, height);
    let sumX = 0, sumY = 0, count = 0;
    let minY = height, maxY = 0;

    // Calculate centroid
    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            if (mask[y * width + x] === 1) {
                sumX += x;
                sumY += y;
                count++;
                minY = Math.min(minY, y);
                maxY = Math.max(maxY, y);
            }
        }
    }

    if (count === 0) return null;

    // Calculate centroid point
    const centroidX = Math.floor(sumX / count);
    const centroidY = Math.floor(sumY / count);

    // Calculate optimal Y position for label
    // If centroid is in upper half, place label at top
    // If in lower half, place at bottom
    const objectHeight = maxY - minY;
    const relativePosition = (centroidY - minY) / objectHeight;

    return {
        x: centroidX,
        y: centroidY,
        labelY: relativePosition < 0.5 ? minY : maxY,
        isTop: relativePosition < 0.5
    };
}

/**
 * Finds the optimal position for label placement on a line
 * @param {number[]} maskFlattened - Flattened binary mask array
 * @param {number} width - Image width
 * @param {number} height - Image height
 * @returns {Object|null} Optimal label position and line characteristics
 */
function findBestLabelPosition(maskFlattened: number[], width: number, height: number) {
    // Analyze pixel distribution
    let pixelsByColumn = new Map<number, number[]>();
    let totalX = 0, totalY = 0, totalPixels = 0;

    // Collect pixels by column
    for (let i = 0; i < maskFlattened.length; i++) {
        if (maskFlattened[i] === 1) {
            const x = i % width;
            const y = Math.floor(i / width);

            if (!pixelsByColumn.has(x)) {
                pixelsByColumn.set(x, []);
            }
            pixelsByColumn.get(x)!.push(y);

            totalX += x;
            totalY += y;
            totalPixels++;
        }
    }

    if (totalPixels === 0) return null;

    // Calculate centroid
    const centroidX = Math.floor(totalX / totalPixels);
    const centroidY = Math.floor(totalY / totalPixels);

    // Analyze line characteristics
    let minX = width, maxX = 0;
    let yVariations = new Map<number, number>();

    pixelsByColumn.forEach((yPoints, x) => {
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        yVariations.set(x, Math.max(...yPoints) - Math.min(...yPoints));
    });

    // Slope analysis
    const avgYVariation = Array.from(yVariations.values()).reduce((a, b) => a + b, 0) / yVariations.size;
    const isAngledLine = avgYVariation > 5;

    // Determine best position
    let bestX = centroidX;
    let bestY = centroidY;

    if (!isAngledLine) {
        // Find densest point for straight lines
        let maxDensity = 0;
        pixelsByColumn.forEach((yPoints, x) => {
            if (yPoints.length > maxDensity) {
                maxDensity = yPoints.length;
                bestX = x;
                bestY = Math.floor(yPoints.reduce((a, b) => a + b, 0) / yPoints.length);
            }
        });
    }

    // Ensure position is on the line
    const yPoints = pixelsByColumn.get(bestX) || [];
    if (yPoints.length > 0) {
        const localMinY = Math.min(...yPoints);
        const localMaxY = Math.max(...yPoints);
        bestY = Math.max(localMinY, Math.min(localMaxY, bestY));
    }

    return {
        x: bestX,
        y: bestY,
        isAngledLine,
        lineWidth: maxX - minX,
        density: (pixelsByColumn.get(bestX) || []).length / height,
        pixelsByColumn
    };
}

/**
 * Draws a label on the canvas with collision avoidance
 * @param {CanvasRenderingContext2D} ctx - Canvas context
 * @param {string} text - Label text
 * @param {Object} position - Initial position and line data
 * @param {number[]} color - RGBA color array
 * @param {number} width - Canvas width
 * @param {number} height - Canvas height
 * @param {Array} prevLabels - Previously placed labels
 * @returns {Object} Final position of the placed label
 */
function drawLabel(ctx, text, position, color, width, height, prevLabels = []) {
    const textWidth = ctx.measureText(text).width;
    const textHeight = 40;
    const padding = { x: 10, y: 5 };

    // Find points along the line
    const linePoints = [];
    position.pixelsByColumn.forEach((yPoints, x) => {
        const avgY = Math.floor(yPoints.reduce((a, b) => a + b, 0) / yPoints.length);
        linePoints.push({ x, y: avgY });
    });

    // Find optimal position on line
    const findBestPositionOnLine = () => {
        // Initial position
        let bestPos = {
            x: position.x,
            y: position.y,
            score: -Infinity
        };

        // Sample along the line
        const sampleStep = Math.max(textWidth / 2, 50); // Sample at least every 50 pixels
        const searchRange = width; // Search across entire width

        for (let offset = -searchRange; offset <= searchRange; offset += sampleStep) {
            const testX = position.x + offset;
            if (testX < 0 || testX >= width) continue;

            // Find nearest line point
            const nearestPoint = linePoints.reduce((nearest, point) => {
                const dist = Math.abs(point.x - testX);
                return dist < Math.abs(nearest.x - testX) ? point : nearest;
            });

            if (!nearestPoint) continue;

            // Check for collisions
            let hasCollision = false;
            const testPos = {
                x: nearestPoint.x,
                y: nearestPoint.y
            };

            // Check canvas boundaries
            if (testPos.x - textWidth / 2 < padding.x ||
                testPos.x + textWidth / 2 > width - padding.x ||
                testPos.y - textHeight / 2 < padding.y ||
                testPos.y + textHeight / 2 > height - padding.y) {
                continue;
            }

            // Check collision with other labels
            for (const label of prevLabels) {
                const xOverlap = Math.abs(testPos.x - label.x) < (textWidth + padding.x * 2);
                const yOverlap = Math.abs(testPos.y - label.y) < (textHeight + padding.y * 2);
                if (xOverlap && yOverlap) {
                    hasCollision = true;
                    break;
                }
            }

            if (hasCollision) continue;

            // Score position
            const distanceFromOriginal = Math.abs(testPos.x - position.x);
            const score = 1000 - distanceFromOriginal; // Prefer positions closer to original

            if (score > bestPos.score) {
                bestPos = {
                    x: testPos.x,
                    y: testPos.y,
                    score
                };
            }
        }

        return bestPos;
    };

    // Find best position
    const bestPosition = findBestPositionOnLine();
    const finalPos = {
        x: bestPosition.x,
        y: bestPosition.y
    };

    // Draw label background
    ctx.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] / 255})`;
    ctx.beginPath();
    ctx.roundRect(
        finalPos.x - textWidth / 2 - padding.x,
        finalPos.y - textHeight / 2 - padding.y,
        textWidth + padding.x * 2,
        textHeight + padding.y * 2,
        8
    );
    ctx.fill();

    // Draw text
    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, finalPos.x, finalPos.y);

    return finalPos;
}

export default function ReviewImage() {
    const dispatch = useDispatch()
    const {
        reviewMainImage,
        reviewMainImageLoading,
        reviewData,
        selectedTable,
        selectedImage,
        selectedImageData,
        reviewType,
        maskImage,
        reviewShow,
        questionSubAssetType,
        hasThumbClick,
        questions
    } = useSelector((state: RootState) => state.review)

    const storageAccess = useStorageAccess()

    const { storageName, token } = storageAccess

    const imageRef = useRef(null)
    const mainImageRef = useRef<HTMLImageElement | null>(null)
    const commentRef = useRef(null)
    const canvasRef = useRef(null)
    const imageContainerRef = useRef<HTMLDivElement>(null);

    const [canvasImage, setCanvasImage] = useState(null)
    const [isAILoading, setIsAILoading] = useState(false)
    const [controller, setController] = useState<AbortController | null>(null);
    const [canvasLayers, setCanvasLayers] = useState<{ id: string; url: string; visible: boolean; order: number; count: number }[]>([]);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const lastMousePos = useRef({ x: 0, y: 0 });
    const isDragging = useRef(false);

    // Pan state management
    const [isPanning, setIsPanning] = useState(false);
    const panStartPos = useRef({ x: 0, y: 0 });
    const panCurrentPos = useRef({ x: 0, y: 0 });

    const [showLayers, setShowLayers] = useState(false);
    const [showOpacitySlider, setShowOpacitySlider] = useState(false);
    const [opacityValue, setOpacityValue] = useState(0.85);

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showGradientOverlay, setShowGradientOverlay] = useState(false);
    const [showZoomControls, setShowZoomControls] = useState(true);

    const clamp = (value: number, min: number, max: number) =>
        Math.min(Math.max(value, min), max);

    const handlePanStart = useCallback((e: React.MouseEvent | TouchEvent) => {
        if (zoomLevel <= 1) return;

        setIsPanning(true);
        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

        panStartPos.current = { x: clientX, y: clientY };
        panCurrentPos.current = { ...translate };

        document.body.style.cursor = 'grabbing';
    }, [zoomLevel, translate]);

    const handlePanMove = useCallback((e: MouseEvent | TouchEvent) => {
        if (!isPanning) return;

        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

        const deltaX = clientX - panStartPos.current.x;
        const deltaY = clientY - panStartPos.current.y;

        // Calculate max pan boundaries based on zoom level
        const maxX = (imageContainerRef.current?.offsetWidth || 0) * (zoomLevel - 1) / 2;
        const maxY = (imageContainerRef.current?.offsetHeight || 0) * (zoomLevel - 1) / 2;

        setTranslate({
            x: clamp(panCurrentPos.current.x + deltaX, -maxX, maxX),
            y: clamp(panCurrentPos.current.y + deltaY, -maxY, maxY)
        });
    }, [isPanning, zoomLevel]);

    const handlePanEnd = useCallback(() => {
        if (!isPanning) return;

        setIsPanning(false);
        document.body.style.cursor = 'default';
    }, [isPanning]);

    useEffect(() => {
        const handleMouseUp = () => handlePanEnd();
        const handleMouseMove = (e: MouseEvent) => handlePanMove(e);
        const handleTouchMove = (e: TouchEvent) => handlePanMove(e);
        const handleTouchEnd = () => handlePanEnd();

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handlePanMove, handlePanEnd]);

    const handleZoom = (direction: 'in' | 'out') => {
        if (!imageContainerRef.current) return;

        // calculate zoom factor
        const zoomFactor = direction === 'in' ? 1.2 : 0.8;
        const newZoom = zoomLevel * zoomFactor;

        // only check minimum zoom limit
        if (direction === 'out' && newZoom <= 1) {
            setZoomLevel(1);
            setTranslate({ x: 0, y: 0 });
            return;
        }

        // zoom based on center
        const container = imageContainerRef.current.getBoundingClientRect();
        const maxTranslateX = container.width * (newZoom - 1) / 2;
        const maxTranslateY = container.height * (newZoom - 1) / 2;

        // calculate new translate values based on center
        const scaleChange = newZoom / zoomLevel;
        const newTranslateX = translate.x * scaleChange;
        const newTranslateY = translate.y * scaleChange;

        setTranslate({
            x: clamp(newTranslateX, -maxTranslateX, maxTranslateX),
            y: clamp(newTranslateY, -maxTranslateY, maxTranslateY)
        });

        setZoomLevel(Number(newZoom.toFixed(2)));
    };

    // Mouse wheel zoom handler
    const handleWheel = useCallback((e: WheelEvent) => {
        // Check if layers popup is over
        const target = e.target as HTMLElement;
        const isOverLayersPopup = target.closest('.layers-popup');

        // If layers popup is over, stop the operation
        if (isOverLayersPopup) {
            return;
        }

        e.preventDefault();

        if (!imageContainerRef.current) return;

        // if zoom out and zoom level is 1 or less, stop the operation
        if (e.deltaY > 0 && zoomLevel <= 1) {
            setZoomLevel(1);
            setTranslate({ x: 0, y: 0 });
            return;
        }

        const container = imageContainerRef.current.getBoundingClientRect();

        // calculate zoom factor
        const zoomFactor = e.deltaY > 0 ? 0.9 : 1.1;
        const newZoom = zoomLevel * zoomFactor;

        // only check minimum zoom limit
        if (newZoom < 1) {
            setZoomLevel(1);
            setTranslate({ x: 0, y: 0 });
            return;
        }

        // Get mouse position relative to container
        const mouseX = e.clientX - container.left;
        const mouseY = e.clientY - container.top;

        // Check if mouse is within container boundaries
        const isMouseOverContainer =
            mouseX >= 0 &&
            mouseX <= container.width &&
            mouseY >= 0 &&
            mouseY <= container.height;

        if (isMouseOverContainer) {
            // if mouse is over container, use mouse position for zoom
            const imageWidth = container.width;
            const imageHeight = container.height;

            // Calculate the point we're zooming to (relative to the image center)
            const zoomPointX = mouseX - imageWidth / 2;
            const zoomPointY = mouseY - imageHeight / 2;

            // Calculate how the zoom affects the distance from center
            const scaleChange = newZoom / zoomLevel;

            // Calculate new translation to maintain the zoom point position
            const newTranslateX = scaleChange * (translate.x - zoomPointX) + zoomPointX;
            const newTranslateY = scaleChange * (translate.y - zoomPointY) + zoomPointY;

            // Calculate boundaries
            const maxTranslateX = imageWidth * (newZoom - 1) / 2;
            const maxTranslateY = imageHeight * (newZoom - 1) / 2;

            setTranslate({
                x: clamp(newTranslateX, -maxTranslateX, maxTranslateX),
                y: clamp(newTranslateY, -maxTranslateY, maxTranslateY)
            });
        } else {
            // if mouse is outside container, zoom towards center
            const scaleChange = newZoom / zoomLevel;
            const newTranslateX = translate.x * scaleChange;
            const newTranslateY = translate.y * scaleChange;

            const maxTranslateX = container.width * (newZoom - 1) / 2;
            const maxTranslateY = container.height * (newZoom - 1) / 2;

            setTranslate({
                x: clamp(newTranslateX, -maxTranslateX, maxTranslateX),
                y: clamp(newTranslateY, -maxTranslateY, maxTranslateY)
            });
        }

        setZoomLevel(newZoom);
    }, [zoomLevel, translate]);

    // Mouse events for panning
    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        if (e.button !== 0) return; // only left click

        isDragging.current = true;
        lastMousePos.current = { x: e.clientX, y: e.clientY };
        document.body.style.cursor = 'grabbing';
    }, []);

    const handleMouseMove = useCallback((e: MouseEvent) => {
        if (!isDragging.current || !imageContainerRef.current) return;

        const deltaX = e.clientX - lastMousePos.current.x;
        const deltaY = e.clientY - lastMousePos.current.y;

        const container = imageContainerRef.current.getBoundingClientRect();
        const maxTranslateX = container.width * (zoomLevel - 1) / 2;
        const maxTranslateY = container.height * (zoomLevel - 1) / 2;

        setTranslate(prev => ({
            x: clamp(prev.x + deltaX, -maxTranslateX, maxTranslateX),
            y: clamp(prev.y + deltaY, -maxTranslateY, maxTranslateY)
        }));

        lastMousePos.current = { x: e.clientX, y: e.clientY };
    }, [zoomLevel]);

    const handleMouseUp = useCallback(() => {
        isDragging.current = false;
        document.body.style.cursor = 'default';
    }, []);

    // Effect for event listeners
    useEffect(() => {
        const container = imageContainerRef.current;
        if (!container) return;

        container.addEventListener('wheel', handleWheel, { passive: false });
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            container.removeEventListener('wheel', handleWheel);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleWheel, handleMouseMove, handleMouseUp]);

    useEffect(() => {
        // first abort current controller and clear canvas
        if (controller && !controller.signal.aborted) {
            controller.abort();
        }

        // immediately clear canvas
        resetCanvas();
        setCanvasLayers([]);
        setCanvasImage(null);
        if (mainImageRef.current) {
            mainImageRef.current = null;
        }

        // new controller create
        const newController = new AbortController();
        setController(newController);

        // Reset zoom and pan when image changes
        setZoomLevel(1);
        setTranslate({ x: 0, y: 0 });

        // AI loading status reset
        setIsAILoading(false);
        dispatch(setReviewMainImageLoading(false));

        if (!reviewShow || !selectedImageData) {
            return () => {
                newController.abort();
            };
        }

        const blobUrl = getBlobUrl(getPreviewBlobName(selectedImageData?.blobName));
        if (!blobUrl) {
            return () => {
                newController.abort();
            };
        }

        const imageElement = new window.Image();
        imageElement.crossOrigin = 'anonymous';
        imageElement.src = blobUrl;
        mainImageRef.current = imageElement;

        const handleImageProcessing = async () => {
            if (newController.signal.aborted) {
                return;
            }

            if (!reviewData?.assetDetail?.inspections?.length || !selectedImageData) return;

            const inspection = reviewData.assetDetail.inspections.find(
                val => val.assetId == selectedTable.id
            );

            if (!inspection?.imageOutputs?.length) {
                setIsAILoading(false);
                return;
            }

            setIsAILoading(true);
            
            // Filter outputs for the selected image
            const reviewOutages = inspection?.imageOutputs.filter(
                val => val.imageId == selectedImageData.id
            );

            // Determine which layers should be visible initially
            const shouldBeVisible = (label) => {
                if (questionSubAssetType && !hasThumbClick) {
                    return label === questionSubAssetType;
                } else if (hasThumbClick && selectedImageData && reviewData?.assetDetail?.detectionQuestions?.length) {
                    const relevantQuestions = reviewData?.assetDetail?.detectionQuestions.filter(
                        question => question.imageIds?.length > 0 && question.imageIds[0] === selectedImageData.id
                    );
                    const questionFilter = questions.filter(q => q.subAssetType && relevantQuestions.some(rq => rq.questionId === q.id));
                    const relevantSubAssetTypes = questionFilter?.map(q => q.subAssetType);
                    return relevantSubAssetTypes.length > 0 ? relevantSubAssetTypes.includes(label) : false;
                }
                return false;
            };

            // Process all layers, both visible and non-visible
            const processLayers = async () => {
                if (newController.signal.aborted) {
                    return;
                }
                
                // first clear all layers
                setCanvasLayers([]);
                
                // processed layers
                const processedLayerIds = new Set();
                
                // first visible layers
                const visibleFirst = async () => {
                    if (newController.signal.aborted) {
                        return;
                    }
                    
                    // process visible layers first
                    for (let outputIndex = 0; outputIndex < reviewOutages.length; outputIndex++) {
                        // each step abort control
                        if (newController.signal.aborted) {
                            return;
                        }
                        
                        const output = reviewOutages[outputIndex];
                        
                        try {
                            if (output.maskBlobName) {
                                if (newController.signal.aborted) {
                                    return;
                                }
                                
                                const hasVisibleLabel = output.labels?.some(label => shouldBeVisible(label));
                                if (!hasVisibleLabel) continue; // no visible label, skip
                                
                                const maskResponse = await dispatch(
                                    getTransmissionStructure({
                                        file: output.maskBlobName,
                                        storageName,
                                        token,
                                    })
                                ).unwrap();

                                if (newController.signal.aborted) {
                                    return;
                                }

                                if (maskResponse) {
                                    const maskData = Array.isArray(maskResponse)
                                        ? maskResponse
                                        : [maskResponse];

                                    // group masks by labels
                                    const groupedMasks = new Map();

                                    maskData.forEach((mask, index) => {
                                        const label = output.labels?.[index] || 'wire';
                                        const labelIndex = TransmissionOptions.findIndex(opt => opt.value === label);
                                        const isVisible = shouldBeVisible(label);
                                        
                                        // only process visible labels
                                        if (!isVisible) return;
                                        
                                        if (!groupedMasks.has(label)) {
                                            groupedMasks.set(label, {
                                                masks: [],
                                                outputIndex: labelIndex >= 0 ? labelIndex : index,
                                                isVisible
                                            });
                                        }
                                        groupedMasks.get(label).masks.push({
                                            ...mask,
                                            score: output.scores?.[index] ?? 0,
                                            label: transmissionLabelMap[label]
                                        });
                                    });

                                    // Process each group
                                    for (const [label, { masks, outputIndex, isVisible }] of groupedMasks.entries()) {
                                        if (newController.signal.aborted) {
                                            return;
                                        }
                                        
                                        // unique layer id
                                        const layerId = `${output.mlModelName}-${output.mlOutputId}-${label}`;
                                        
                                        // if layer is already processed, skip
                                        if (processedLayerIds.has(layerId)) continue;
                                        
                                        // mark as processed
                                        processedLayerIds.add(layerId);
                                        
                                        const colorIndex = outputIndex % TransmissionColors.length;
                                        const color = transmissionColorMap[label] || TransmissionColors[colorIndex];

                                        const canvasUrl = await drawWireMaskOptimized({
                                            masks,
                                            imageElement,
                                            color,
                                            signal: newController.signal
                                        });

                                        if (newController.signal.aborted) {
                                            return;
                                        }

                                        if (canvasUrl) {
                                            setCanvasLayers(prev => [
                                                ...prev,
                                                {
                                                    id: layerId,
                                                    url: canvasUrl,
                                                    visible: isVisible,
                                                    color,
                                                    count: masks.length,
                                                    label: label,
                                                    outputIndex: colorIndex,
                                                    style: { opacity: opacityValue }
                                                }
                                            ]);
                                        }
                                    }
                                }
                            } else if (output.detections?.length) {
                                if (newController.signal.aborted) {
                                    return;
                                }
                                
                                // For bounding box detections
                                const hasVisibleLabel = output.labels?.some(label => shouldBeVisible(label));
                                if (!hasVisibleLabel) continue; // no visible label, skip
                                
                                // unique layer id
                                const layerId = `bbox-${output.mlModelName}-${output.mlOutputId}`;
                                
                                // if layer is already processed, skip
                                if (processedLayerIds.has(layerId)) continue;
                                
                                // mark as processed
                                processedLayerIds.add(layerId);
                                
                                const newLayers = await handleDrawOptimized(imageElement, output, outputIndex, newController.signal);
                                
                                if (newController.signal.aborted) {
                                    return;
                                }
                                
                                if (newLayers.length > 0) {
                                    setCanvasLayers(prev => [
                                        ...prev,
                                        ...newLayers.map(layer => ({
                                            ...layer,
                                            id: layerId,
                                            visible: true,
                                            label: output.labels?.[0] || 'unknown',
                                            style: { opacity: opacityValue }
                                        }))
                                    ]);
                                }
                            }
                        } catch (error) {
                            // Abort control
                            if (newController.signal.aborted) {
                                return;
                            }
                            console.error('Output processing error:', output.id, error);
                        }
                    }
                };
                
                // then process invisible layers
                const invisibleNext = async () => {
                    if (newController.signal.aborted) {
                        return;
                    }
                    
                    for (let outputIndex = 0; outputIndex < reviewOutages.length; outputIndex++) {
                        // each step abort control
                        if (newController.signal.aborted) {
                            return;
                        }
                        
                        const output = reviewOutages[outputIndex];
                        
                        try {
                            if (output.maskBlobName) {
                                if (newController.signal.aborted) {
                                    return;
                                }
                                
                                const maskResponse = await dispatch(
                                    getTransmissionStructure({
                                        file: output.maskBlobName,
                                        storageName,
                                        token,
                                    })
                                ).unwrap();

                                if (newController.signal.aborted) {
                                    return;
                                }

                                if (maskResponse) {
                                    const maskData = Array.isArray(maskResponse)
                                        ? maskResponse
                                        : [maskResponse];

                                    // group masks by labels
                                    const groupedMasks = new Map();

                                    maskData.forEach((mask, index) => {
                                        const label = output.labels?.[index] || 'wire';
                                        const labelIndex = TransmissionOptions.findIndex(opt => opt.value === label);
                                        const isVisible = shouldBeVisible(label);
                                        
                                        // only process invisible labels
                                        if (isVisible) return;
                                        
                                        if (!groupedMasks.has(label)) {
                                            groupedMasks.set(label, {
                                                masks: [],
                                                outputIndex: labelIndex >= 0 ? labelIndex : index,
                                                isVisible
                                            });
                                        }
                                        groupedMasks.get(label).masks.push({
                                            ...mask,
                                            score: output.scores?.[index] ?? 0,
                                            label: transmissionLabelMap[label]
                                        });
                                    });

                                    // Process each group
                                    for (const [label, { masks, outputIndex, isVisible }] of groupedMasks.entries()) {
                                        if (newController.signal.aborted) {
                                            return;
                                        }
                                        
                                        // unique layer id
                                        const layerId = `${output.mlModelName}-${output.mlOutputId}-${label}`;
                                        
                                        // if layer is already processed, skip
                                        if (processedLayerIds.has(layerId)) continue;
                                        
                                        // mark as processed
                                        processedLayerIds.add(layerId);
                                        
                                        const colorIndex = outputIndex % TransmissionColors.length;
                                        const color = transmissionColorMap[label] || TransmissionColors[colorIndex];

                                        const canvasUrl = await drawWireMaskOptimized({
                                            masks,
                                            imageElement,
                                            color,
                                            signal: newController.signal
                                        });

                                        if (newController.signal.aborted) {
                                            return;
                                        }

                                        if (canvasUrl) {
                                            setCanvasLayers(prev => [
                                                ...prev,
                                                {
                                                    id: layerId,
                                                    url: canvasUrl,
                                                    visible: isVisible,
                                                    color,
                                                    count: masks.length,
                                                    label: label,
                                                    outputIndex: colorIndex,
                                                    style: { opacity: opacityValue }
                                                }
                                            ]);
                                        }
                                    }
                                }
                            } else if (output.detections?.length) {
                                if (newController.signal.aborted) {
                                    return;
                                }
                                
                                // For bounding box detections
                                const hasVisibleLabel = output.labels?.some(label => shouldBeVisible(label));
                                if (hasVisibleLabel) continue; // visible label, skip
                                
                                // unique layer id
                                const layerId = `bbox-${output.mlModelName}-${output.mlOutputId}`;
                                
                                // if layer is already processed, skip
                                if (processedLayerIds.has(layerId)) continue;
                                
                                // mark as processed
                                processedLayerIds.add(layerId);
                                
                                const newLayers = await handleDrawOptimized(imageElement, output, outputIndex, newController.signal);
                                
                                if (newController.signal.aborted) {
                                    return;
                                }
                                
                                if (newLayers.length > 0) {
                                    setCanvasLayers(prev => [
                                        ...prev,
                                        ...newLayers.map(layer => ({
                                            ...layer,
                                            id: layerId,
                                            visible: true, // set as invisible
                                            label: output.labels?.[0] || 'unknown',
                                            style: { opacity: opacityValue }
                                        }))
                                    ]);
                                }
                            }
                        } catch (error) {
                            if (newController.signal.aborted) {
                                return;
                            }
                            console.error('Output processing error:', output.id, error);
                        }
                    }
                };
                
                // process visible layers first
                await visibleFirst();
                
                // Abort control
                if (newController.signal.aborted) {
                    return;
                }
                
                // then process invisible layers
                await invisibleNext();
            };

            // Process all layers
            try {
                await processLayers();
            } catch (error) {
                if (!newController.signal.aborted) {
                    console.error('Error processing layers:', error);
                    setIsAILoading(false);
                }
            }
            
            if (!newController.signal.aborted) {
                setIsAILoading(false);
            }
        };

        const loadHighQualityImage = (blobUrl: string, signal: AbortSignal) => {
            const qualityImageElement = new window.Image();
            qualityImageElement.crossOrigin = 'anonymous';
            qualityImageElement.src = blobUrl;

            const onLoad = () => {
                if (signal.aborted) return;
                mainImageRef.current = qualityImageElement;
                setCanvasImage(null);
            };

            const onError = () => {
                if (signal.aborted) return;
                console.error('High-quality image loading failed');
            };

            qualityImageElement.onload = onLoad;
            qualityImageElement.onerror = onError;

            signal.addEventListener('abort', () => {
                qualityImageElement.onload = null;
                qualityImageElement.onerror = null;
                qualityImageElement.src = '';
            });
        };

        imageElement.onload = () => {
            if (newController.signal.aborted) return;
            handleImageProcessing();
            dispatch(setReviewMainImageLoading(false))
            const blobUrl = getBlobUrl(selectedImageData?.blobName);
            if (!blobUrl) return;
            loadHighQualityImage(blobUrl, newController.signal);
        }

        imageElement.onerror = () => {
            if (newController.signal.aborted) return;
            dispatch(setReviewMainImageLoading(false))
            console.error('Image loading failed');
        };

        return () => {
            newController.abort();
            mainImageRef.current = null;
            setCanvasImage(null);
            resetCanvas();
            setIsAILoading(false);
            dispatch(setReviewMainImageLoading(false))
        };
    }, [reviewData, selectedImage, selectedImageData, reviewShow, questionSubAssetType, hasThumbClick, storageAccess]);

    /**
     * Resets the canvas by clearing its context and setting its dimensions to zero.
     */
    function resetCanvas() {
        if (canvasRef.current) {
            setCanvasImage(null);

            // 2D context clear 
            const ctx = canvasRef.current.getContext('2d', {
                willReadFrequently: true
            });
            if (ctx) {
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            }

            // WebGL context dispose
            try {
                const gl = canvasRef.current.getContext('webgl');
                if (gl) {
                    gl.getExtension('WEBGL_lose_context')?.loseContext();
                }
            } catch (e) {
                console.warn('WebGL context cleanup failed:', e);
            }

            canvasRef.current.width = 0;
            canvasRef.current.height = 0;
        }
    }

    /**
     * Draws bounding boxes and confidence scores on the canvas based on the image predictions.
     * Scales and adjusts the drawing based on whether the image is a preview or full-resolution.
     *
     * @param {boolean} preview - Whether the image being drawn is a preview.
     */
    async function handleDraw(preview: boolean, imageElement: HTMLImageElement, outage: any, outputIndex: number) {
        const outputColor = getColorForOutput(outputIndex);

        const rgbaColor = `rgba(${outputColor[0]}, ${outputColor[1]}, ${outputColor[2]}, 255)`;

        try {

            const imgPrediction = {
                b_box: outage.detections.length === 4 ? [[
                    [outage.detections[0], outage.detections[1]],
                    [outage.detections[2], outage.detections[3]]
                ]] : outage.detections,
                conf_list: outage.scores,
                label_list: outage.labels.map(label => transmissionLabelMap[label] || label)
            };

            if (!imageElement || !imgPrediction.b_box.length) {
                setCanvasImage(null);
                return [];
            }

            await waitForImageLoad(imageElement);

            const width = imageElement.naturalWidth;
            const height = imageElement.naturalHeight;
            const naturalWidth = preview ? selectedImageData.width : width;
            const multiplier = width / naturalWidth;

            const newLayers = [];

            for (let i = 0; i < imgPrediction.b_box.length; i++) {
                const tempCanvas = document.createElement('canvas');
                const scaleFactor = window.devicePixelRatio || 1; // Retina display support

                // high resolution canvas settings
                tempCanvas.width = width * scaleFactor;
                tempCanvas.height = height * scaleFactor;
                tempCanvas.style.width = `${width}px`;
                tempCanvas.style.height = `${height}px`;

                const ctx = tempCanvas.getContext('2d');
                ctx.scale(scaleFactor, scaleFactor); // resolution scaling
                ctx.imageSmoothingEnabled = true; // anti-aliasing

                // bounding box drawing
                const bbox = imgPrediction.b_box[i];
                const x1 = Array.isArray(bbox[0]) ? bbox[0][0] * multiplier : bbox[0] * multiplier;
                const y1 = Array.isArray(bbox[0]) ? bbox[0][1] * multiplier : bbox[1] * multiplier;
                const x2 = Array.isArray(bbox[1]) ? bbox[1][0] * multiplier : bbox[2] * multiplier;
                const y2 = Array.isArray(bbox[1]) ? bbox[1][1] * multiplier : bbox[3] * multiplier;

                ctx.strokeStyle = rgbaColor;
                ctx.lineWidth = 5;
                ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

                // label drawing
                if (imgPrediction.label_list?.[i]) {
                    const conf = (imgPrediction.conf_list[i] * 100).toFixed(0);
                    const text = `${imgPrediction.label_list[i]} ${conf}%`;

                    // text quality settings
                    ctx.font = '13px Roboto, Arial, sans-serif';
                    ctx.textRendering = 'geometricPrecision'; // text anti-aliasing
                    const textMetrics = ctx.measureText(text);
                    const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent + 5;
                    const padding = 2 * scaleFactor;
                    const verticalPadding = 0.3 * scaleFactor;

                    // background size
                    const labelHeight = textHeight + verticalPadding * 2;
                    const labelWidth = textMetrics.width + padding * 2;

                    // vertical position calculation
                    let labelY = y1 - labelHeight - 2;

                    // if there is no space above, check below
                    if (labelY < 5) {
                        labelY = y2 + 2;

                        // if there is no space below, force to top (minimum 2px space)
                        if (labelY + labelHeight > height - 2) {
                            labelY = Math.max(2, y1 - labelHeight - 2);
                        }
                    }

                    // horizontal position limits
                    const labelX = Math.max(
                        2, // minimum left space
                        Math.min(
                            x1 + (x2 - x1) / 2 - labelWidth / 2,
                            width - labelWidth - 2 // minimum right space
                        )
                    );

                    // background drawing
                    ctx.fillStyle = rgbaColor;
                    ctx.beginPath();
                    ctx.roundRect(
                        labelX,
                        labelY,
                        labelWidth,
                        labelHeight,
                        2 // light rounded corners
                    );
                    ctx.fill();

                    // text drawing
                    ctx.fillStyle = 'rgba(255, 255, 255, 1)';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    ctx.fillText(
                        text,
                        labelX + labelWidth / 2,
                        labelY + labelHeight - verticalPadding
                    );

                    newLayers.push({
                        id: `bbox-${outage.mlModelName}-${Date.now()}-${i}`,
                        url: tempCanvas.toDataURL(),
                        visible: true,
                        color: getColorForOutput(outputIndex)
                    });
                }
            }

            return newLayers;
        } catch (error) {
            console.error('Canvas drawing error:', error);
            setCanvasImage(null);
            return [];
        }
    }

    const getBlobUrl = (blobName: string) => {
        if (!storageAccess?.token || !blobName || !storageName) return null;
        return storageAccess.token.replace('/?', `/${storageName}/${blobName}?`);
    }

    // create one canvas and draw all masks on it
    const drawWireMask = ({ masks, imageElement, color }) => {
        // use the size of the first mask
        const [height, width] = masks[0].size || [masks[0].height, masks[0].width];

        // create one canvas and draw all masks on it
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = width;
        tempCanvas.height = height;
        const ctx = tempCanvas.getContext('2d');

        // for each mask
        masks.forEach((mask, index) => {
            const counts = rleFromString(mask.counts);
            const maskFlattened = decodeCocoRLE([height, width], counts);

            // draw the mask
            const imageData = ctx.getImageData(0, 0, width, height);
            const data = imageData.data;

            let minX = width, maxX = 0, minY = height, maxY = 0;
            let pixelCount = 0;
            let sumX = 0, sumY = 0;

            for (let i = 0; i < maskFlattened.length; i++) {
                if (maskFlattened[i] === 1) {
                    const y = Math.floor(i / width);
                    const x = i % width;

                    minX = Math.min(minX, x);
                    maxX = Math.max(maxX, x);
                    minY = Math.min(minY, y);
                    maxY = Math.max(maxY, y);

                    const idx = (y * width + x) * 4;

                    data[idx] = color[0];     // R
                    data[idx + 1] = color[1];  // G
                    data[idx + 2] = color[2];  // B
                    data[idx + 3] = color[3];  // A

                    sumX += x;
                    sumY += y;
                    pixelCount++;
                }
            }

            ctx.putImageData(imageData, 0, 0);

            // label drawing
            if (mask.label && mask.score) {
                const labelPos = findBestLabelPosition(maskFlattened, width, height);
                if (!labelPos) return;

                ctx.font = '40px roboto';
                const text = `${mask.label} ${Math.round(mask.score * 100)}%`;

                const finalPos = drawLabel(ctx, text, labelPos, color, width, height, window.__prevLabels || []);

                // save the position
                if (!window.__prevLabels) window.__prevLabels = [];
                window.__prevLabels.push({
                    x: finalPos.x,
                    y: finalPos.y,
                    width: ctx.measureText(text).width + 20,
                    height: 50
                });
            }
        });

        return tempCanvas.toDataURL();
    };

    // toggle layer visibility
    const toggleLayerVisibility = (layerId: string) => {
        setCanvasLayers(prevLayers =>
            prevLayers.map(layer =>
                layer.id === layerId ? { ...layer, visible: !layer.visible } : layer
            )
        );
    };

    // handle opacity change
    const handleOpacityChange = (value: number) => {
        const normalizedOpacity = Math.min(1, Math.max(0.1, value / 100)) // 0.1-1 arası
        setOpacityValue(normalizedOpacity)

        setCanvasLayers(prevLayers =>
            prevLayers.map(layer => ({
                ...layer,
                style: { opacity: normalizedOpacity }
            }))
        )
    };

    // set default opacity
    useEffect(() => {
        setCanvasLayers(prevLayers =>
            prevLayers.map(layer => ({
                ...layer,
                style: { opacity: 0.85 }
            }))
        )
    }, []);

    const toggleFullscreen = () => {
        if (!imageContainerRef.current) return;

        if (!document.fullscreenElement) {
            imageContainerRef.current.requestFullscreen().then(() => {
                setIsFullscreen(true);
            }).catch(err => {
                console.error('fullscreen error', err);
            });
        } else {
            document.exitFullscreen().then(() => {
                setIsFullscreen(false);
            }).catch(err => {
                console.error('fullscreen exit error', err);
            });
        }
    };
    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    // Optimized drawing function - using OffscreenCanvas
    async function drawWireMaskOptimized({ masks, imageElement, color, signal }) {
        if (signal && signal.aborted) {
            return null;
        }

        // use the first mask's size
        const [height, width] = masks[0].size || [masks[0].height, masks[0].width];

        // use OffscreenCanvas (if supported)
        const useOffscreen = typeof OffscreenCanvas !== 'undefined';
        const tempCanvas = useOffscreen
            ? new OffscreenCanvas(width, height)
            : document.createElement('canvas');

        if (!useOffscreen) {
            tempCanvas.width = width;
            tempCanvas.height = height;
        }

        const ctx = tempCanvas.getContext('2d', { alpha: true, willReadFrequently: false });

        // for performance, create ImageData once
        const imageData = ctx.createImageData(width, height);
        const data = imageData.data;

        // draw all masks
        const prevLabels = [];

        for (const mask of masks) {
            if (signal && signal.aborted) {
                return null;
            }

            const counts = rleFromString(mask.counts);
            const maskFlattened = decodeCocoRLE([height, width], counts);

            // draw mask
            for (let i = 0; i < maskFlattened.length; i++) {
                if (maskFlattened[i] === 1) {
                    const idx = i * 4;
                    data[idx] = color[0];     // R
                    data[idx + 1] = color[1]; // G
                    data[idx + 2] = color[2]; // B
                    data[idx + 3] = color[3]; // A
                }
            }

            // draw label
            if (mask.label && mask.score) {
                const labelPos = findBestLabelPosition(maskFlattened, width, height);
                if (labelPos) {
                    prevLabels.push({
                        pos: labelPos,
                        text: `${mask.label} ${Math.round(mask.score * 100)}%`,
                        color
                    });
                }
            }
        }

        // draw all masks
        ctx.putImageData(imageData, 0, 0);

        // draw labels
        ctx.font = '40px roboto';

        for (const { pos, text, color } of prevLabels) {
            if (signal && signal.aborted) {
                return null;
            }

            const finalPos = drawLabel(ctx, text, pos, color, width, height, window.__prevLabels || []);

            // save position
            if (!window.__prevLabels) window.__prevLabels = [];
            window.__prevLabels.push({
                x: finalPos.x,
                y: finalPos.y,
                width: ctx.measureText(text).width + 20,
                height: 50
            });
        }

        if (signal && signal.aborted) {
            return null;
        }

        // convert canvas content to URL
        if (useOffscreen) {
            const blob = await tempCanvas.convertToBlob({ type: 'image/png', quality: 0.8 });
            return URL.createObjectURL(blob);
        } else {
            return tempCanvas.toDataURL('image/png', 0.8);
        }
    }

    // Optimized bounding box drawing
    async function handleDrawOptimized(imageElement, outage, outputIndex, signal) {
        if (signal && signal.aborted) {
            return [];
        }

        const outputColor = getColorForOutput(outputIndex);
        const rgbaColor = `rgba(${outputColor[0]}, ${outputColor[1]}, ${outputColor[2]}, 255)`;

        try {
            const imgPrediction = {
                b_box: outage.detections.length === 4 ? [[
                    [outage.detections[0], outage.detections[1]],
                    [outage.detections[2], outage.detections[3]]
                ]] : outage.detections,
                conf_list: outage.scores,
                label_list: outage.labels.map(label => transmissionLabelMap[label] || label)
            };

            if (!imageElement || !imgPrediction.b_box.length) {
                return [];
            }

            await waitForImageLoad(imageElement);


            if (signal && signal.aborted) {
                return [];
            }

            const width = imageElement.naturalWidth;
            const height = imageElement.naturalHeight;
            const naturalWidth = selectedImageData.width;
            const multiplier = width / naturalWidth;

            // all bounding boxes in one canvas
            const useOffscreen = typeof OffscreenCanvas !== 'undefined';
            const tempCanvas = useOffscreen
                ? new OffscreenCanvas(width, height)
                : document.createElement('canvas');

            if (!useOffscreen) {
                tempCanvas.width = width;
                tempCanvas.height = height;
            }

            const ctx = tempCanvas.getContext('2d', { alpha: true });

            // draw all bounding boxes
            for (let i = 0; i < imgPrediction.b_box.length; i++) {
                if (signal && signal.aborted) {
                    return [];
                }

                const bbox = imgPrediction.b_box[i];
                const x1 = Array.isArray(bbox[0]) ? bbox[0][0] * multiplier : bbox[0] * multiplier;
                const y1 = Array.isArray(bbox[0]) ? bbox[0][1] * multiplier : bbox[1] * multiplier;
                const x2 = Array.isArray(bbox[1]) ? bbox[1][0] * multiplier : bbox[2] * multiplier;
                const y2 = Array.isArray(bbox[1]) ? bbox[1][1] * multiplier : bbox[3] * multiplier;

                ctx.strokeStyle = rgbaColor;
                ctx.lineWidth = 5;
                ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

                // Tag drawing
                if (imgPrediction.label_list?.[i]) {
                    const conf = (imgPrediction.conf_list[i] * 100).toFixed(0);
                    const text = `${imgPrediction.label_list[i]} ${conf}%`;

                    ctx.font = '13px Roboto, Arial, sans-serif';
                    const textMetrics = ctx.measureText(text);
                    const textHeight = 20;
                    const padding = 2;
                    const verticalPadding = 0.3;

                    const labelHeight = textHeight + verticalPadding * 2;
                    const labelWidth = textMetrics.width + padding * 2;

                    let labelY = y1 - labelHeight - 2;

                    if (labelY < 5) {
                        labelY = y2 + 2;
                        if (labelY + labelHeight > height - 2) {
                            labelY = Math.max(2, y1 - labelHeight - 2);
                        }
                    }

                    const labelX = Math.max(
                        2,
                        Math.min(
                            x1 + (x2 - x1) / 2 - labelWidth / 2,
                            width - labelWidth - 2
                        )
                    );

                    ctx.fillStyle = rgbaColor;
                    ctx.beginPath();
                    ctx.roundRect(
                        labelX,
                        labelY,
                        labelWidth,
                        labelHeight,
                        2
                    );
                    ctx.fill();

                    ctx.fillStyle = 'rgba(255, 255, 255, 1)';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillText(
                        text,
                        labelX + labelWidth / 2,
                        labelY + labelHeight / 2
                    );
                }
            }

            if (signal && signal.aborted) {
                return [];
            }

            // convert canvas content to URL
            if (useOffscreen) {
                const blob = await tempCanvas.convertToBlob({ type: 'image/png', quality: 0.8 });
                return [{
                    id: `bbox-${outage.mlModelName}-${Date.now()}`,
                    url: URL.createObjectURL(blob),
                    visible: true,
                    color: outputColor
                }];
            } else {
                return [{
                    id: `bbox-${outage.mlModelName}-${Date.now()}`,
                    url: tempCanvas.toDataURL('image/png', 0.8),
                    visible: true,
                    color: outputColor
                }];
            }
        } catch (error) {
            console.error('Canvas drawing error:', error);
            return [];
        }
    }

    return (
        <div className="rounded-md mt-4 absolute w-full">
            <canvas
                ref={canvasRef}
                id="myCanvas"
                width={0}
                height={0}
                style={{ display: 'none' }}
                crossOrigin="anonymous"
            ></canvas>
            <div className="grid grid-rows-11 gap-2 h-[calc(100vh-100px)]">
                <div className="row-span-9 overflow-hidden rounded-xl relative">
                    {reviewMainImageLoading && (
                        <Skeleton
                            width="100vw"
                            height="100vh"
                            borderRadius="16px"
                        ></Skeleton>
                    )}
                    {mainImageRef.current && (
                        <div
                            ref={imageContainerRef}
                            className={`relative w-full h-full select-none ${isFullscreen ? 'bg-black' : ''}`}
                            onMouseDown={handleMouseDown}
                            style={{
                                cursor: isDragging.current ? 'grabbing' : (zoomLevel > 1 ? 'grab' : 'default'),
                                overflow: 'hidden'
                            }}
                        >
                            {/* Zoom controls */}
                            <div className="absolute top-0 right-5 rotate-image-header">
                                <div className="bg-white dark:bg-gray-800 rounded-md shadow-sm flex items-center">
                                    <div className={`flex gap-1 items-center transition-all duration-300 ${showZoomControls ? 'opacity-100 w-auto' : 'opacity-0 w-0 overflow-hidden'}`}>
                                        <div className="relative">
                                            <Button
                                                variant="plain"
                                                size="xs"
                                                icon={<IoLayersSharp className="text-lg" />}
                                                onClick={() => setShowLayers(!showLayers)}
                                                className="relative"
                                                id="layers-button-tooltip"
                                            />
                                            <Tooltip 
                                                target="#layers-button-tooltip" 
                                                position="top" 
                                                mouseTrack={false}
                                                mouseTrackTop={-30}
                                                showDelay={50}
                                                style={{ marginTop: '-15px' }}
                                                className="tooltip-custom"
                                                content="Use the sliders below to toggle different AI detections on or off for this image"
                                            />
                                        </div>
                                        <Button
                                            variant="plain"
                                            size="xs"
                                            icon={<HiZoomOut className="text-lg" />}
                                            onClick={() => handleZoom('out')}
                                            disabled={zoomLevel <= 1}
                                            className={zoomLevel <= 1 ? 'opacity-50 cursor-not-allowed' : ''}
                                        />
                                        <Button
                                            variant="plain"
                                            size="xs"
                                            icon={<HiZoomIn className="text-lg" />}
                                            onClick={() => handleZoom('in')}
                                        />
                                        <div className="relative">
                                            <Button
                                                variant="plain"
                                                size="xs"
                                                icon={isFullscreen ? <MdFullscreenExit className="text-lg" /> : <MdFullscreen className="text-lg" />}
                                                onClick={toggleFullscreen}
                                                id="fullscreen-button-tooltip"
                                            />
                                            <Tooltip 
                                                target="#fullscreen-button-tooltip" 
                                                position="top" 
                                                mouseTrack={false}
                                                mouseTrackTop={-30}
                                                showDelay={50}
                                                style={{ marginTop: '-15px' }}
                                                className="tooltip-custom"
                                                content={isFullscreen ? "Exit fullscreen" : "Enter fullscreen mode"}
                                            />
                                        </div>
                                    </div>
                                    {showZoomControls ? (
                                        <Button
                                            variant="plain"
                                            size="xs"
                                            icon={<MdClose className="text-lg" />}
                                            onClick={() => setShowZoomControls(false)}
                                            className="ml-0"
                                            id="toggle-controls-tooltip"
                                        />
                                    ) : (
                                        <Button
                                            variant="plain"
                                            size="xs"
                                            icon={<TbAdjustmentsHorizontal className="text-lg" />}
                                            onClick={() => setShowZoomControls(true)}
                                            id="toggle-controls-tooltip"
                                        />
                                    )}
                                    <Tooltip 
                                        target="#toggle-controls-tooltip" 
                                        position="top" 
                                        mouseTrack={false}
                                        mouseTrackTop={-30}
                                        showDelay={50}
                                        style={{ marginTop: '-15px' }}
                                        className="tooltip-custom"
                                        content={showZoomControls ? "Hide controls" : "Show controls"}
                                    />
                                </div>
                            </div>
                            {/* Layers popup */}
                            {showLayers && (
                                <div
                                    className="absolute right-5 top-12 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-xl border border-gray-200 dark:border-gray-700 z-50 w-72 layers-popup"
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseMove={(e) => e.stopPropagation()}
                                    style={{ cursor: 'default' }}
                                >
                                    <div className="flex items-center justify-between mb-3">
                                        <div className="flex items-center gap-2">
                                            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Detections</h3>
                                            <Button
                                                variant="filled"
                                                size="xs"
                                                icon={<MdOutlineOpacity className={`text-lg ${showOpacitySlider && 'text-trueBlue'}`} />}
                                                onClick={() => setShowOpacitySlider(!showOpacitySlider)}
                                            />
                                            <button
                                                onClick={() => setCanvasLayers(prev => prev.map(l => ({ ...l, visible: !prev.some(l => l.visible) })))}
                                                className="px-2 py-1 text-sm bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                                            >
                                                {canvasLayers.some(l => l.visible) ? 'Hide all' : 'Show all'}
                                            </button>
                                        </div>
                                        <IoClose
                                            className="text-xl cursor-pointer text-gray-500 hover:text-gray-700 dark:text-gray-300"
                                            onClick={() => setShowLayers(false)}
                                        />
                                    </div>

                                    {/* Layers list with scroll */}
                                    <div
                                        className="space-y-2 max-h-60 overflow-y-auto mb-2"
                                    >
                                        {Array.from(
                                            canvasLayers.reduce((groupMap, layer) => {
                                                const groupId = `${layer.label}`; // only group by label

                                                if (!groupMap.has(groupId)) {
                                                    groupMap.set(groupId, {
                                                        id: groupId,
                                                        visible: layer.visible,
                                                        count: layer.count,
                                                        color: layer.color,
                                                        label: layer.label
                                                    });
                                                }
                                                return groupMap;
                                            }, new Map()).values()
                                        ).map((group) => (
                                            <div
                                                key={group.id}
                                                className="flex items-center gap-3 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors cursor-pointer border-b border-gray-100 dark:border-gray-700"
                                                onClick={() => {
                                                    const allVisible = canvasLayers
                                                        .filter(l => l.label === group.label)
                                                        .every(l => l.visible);

                                                    setCanvasLayers(prev =>
                                                        prev.map(layer =>
                                                            layer.label === group.label
                                                                ? { ...layer, visible: !allVisible }
                                                                : layer
                                                        )
                                                    );
                                                }}
                                            >
                                                <div
                                                    className="w-3 h-3 rounded-full transition-colors"
                                                    style={{
                                                        backgroundColor: group.visible
                                                            ? `rgba(${group.color.join(',')})`
                                                            : '#9CA3AF',
                                                        opacity: group.visible ? 1 : 0.5
                                                    }}
                                                />
                                                <div className="flex-1 max-w-[150px]">
                                                    <div className="text-sm font-medium text-gray-700 dark:text-gray-200 text-truncate max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        {transmissionLabelMap[group.label] || group.label}
                                                    </div>
                                                    <div className="text-xs text-gray-500 dark:text-gray-400">
                                                        {group.count} {group.count > 1 ? 'detections' : 'detection'}
                                                    </div>
                                                </div>
                                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                                    <InputSwitch
                                                        checked={group.visible}
                                                        className="scale-75 custom-inputswitch"
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Opacity slider integrated within layers popup */}
                                    {showOpacitySlider && (
                                        <div
                                            className="pt-4 border-t border-gray-200 dark:border-gray-700 cursor-default"
                                            onMouseDown={(e) => e.stopPropagation()}
                                            onMouseMove={(e) => e.stopPropagation()}
                                        >
                                            <div className="flex items-center justify-between mb-2">
                                                <span className="text-sm text-gray-700 dark:text-gray-300">Opacity</span>
                                                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    {Math.round(opacityValue * 100)}%
                                                </span>
                                            </div>
                                            <div
                                                onMouseDown={(e) => e.stopPropagation()}
                                                onMouseMove={(e) => e.stopPropagation()}
                                            >
                                                <Slider
                                                    value={opacityValue * 100}
                                                    onChange={(e) => handleOpacityChange(e.value)}
                                                    className="w-full custom-opacity-slider"
                                                    min={10}
                                                    max={100}
                                                    step={5}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Image container */}
                            <div
                                className="absolute top-0 left-0 w-full h-full will-change-transform"
                                style={{
                                    transform: `translate3d(${translate.x}px, ${translate.y}px, 0) scale(${zoomLevel})`,
                                    transformOrigin: 'center',
                                    transition: isDragging.current ? 'none' : 'transform 0.2s ease-out'
                                }}
                            >
                                <img
                                    ref={imageRef}
                                    src={mainImageRef.current.src}
                                    className="w-full h-full object-contain"
                                    draggable={false}
                                    loading="eager"
                                    decoding="async"
                                    importance="high"
                                    key={mainImageRef.current.src}
                                    style={{
                                        willChange: 'transform',
                                        backfaceVisibility: 'hidden',
                                        WebkitBackfaceVisibility: 'hidden',
                                        transform: 'translate3d(0,0,0)',
                                        WebkitTransform: 'translate3d(0,0,0)'
                                    }}
                                />

                                {canvasLayers.map(layer => (
                                    <img
                                        key={layer.id}
                                        src={layer.url}
                                        className="absolute top-0 left-0 w-full h-full object-contain pointer-events-none"
                                        style={{
                                            visibility: layer.visible ? 'visible' : 'hidden',
                                            opacity: layer.style?.opacity ?? 1,
                                            userSelect: 'none',
                                            pointerEvents: 'none'
                                        }}
                                        draggable={false}
                                        loading="eager"
                                        decoding="async"
                                    />
                                ))}

                                {/* Image Number Indicator */}
                                {reviewData?.assetDetail?.inspections?.length > 0 && selectedImageData && (
                                    <div className="absolute bottom-4 left-4 bg-black bg-opacity-60 text-white px-3 py-1 rounded-md z-10 text-sm font-medium">
                                        {selectedImage + 1}/{reviewData.assetDetail.inspections.find(i => i.assetId === selectedTable.id)?.images?.length || 0}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {/* AI Processing Loading Indicator */}
                    {isAILoading && (
                        <div className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full flex items-center space-x-2">
                            <span className="text-sm font-medium">AI</span>
                            <div className="relative flex items-center justify-center">
                                {/* Center dot */}
                                <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse" />

                                {/* Expanding rings */}
                                <div className="absolute w-2 h-2 bg-green-400 rounded-full animate-ping" />
                                <div className="absolute w-2 h-2 bg-green-400 rounded-full animate-ping delay-150" />
                            </div>
                        </div>
                    )}
                    <FeedbackButtons />
                </div>
                <div className="row-span-2 pl-2 w-full h-full">
                    <div className="overflow-y-auto h-full">
                        <div className="overflow-hidden py-2">
                            <div className="relative">
                                <ReviewThumbs onMultipleRows={(hasMultipleRows) => setShowGradientOverlay(hasMultipleRows)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
