// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import NotificationBanner from '@/modules/review-2/general/NotificationBanner'
import { Button } from '@/components/ui'
import StatusBadge from '@/modules/review-2/general/StatusBadge'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { useSelector } from 'react-redux'
import { RootState, setSelectedDetection } from '@/store'
import BaseService from '@/services/BaseService'
import RejectDialog from '@/modules/review-2/review/RejectDialog'
import AIBanner from '@/modules/review-2/general/AIBanner'
import { DETECTION_TYPE_MAPPING } from '@/utils'
import {DetectionForm} from '@/modules/review-2/review/DetectionForm'
import { useDispatch } from 'react-redux'
import { MdArrowBack } from 'react-icons/md'

interface TransformerFormData {
    numberOfTransformers: string | null
    condition: string | null
}

interface InsulatorFormData {
    type: string | null
    material: string | null
}

interface TransmissionFormData {
    type: string | null
}

interface ServiceOption {
    label: string
    description: string
    value: string
}

interface ServiceOption {
    label: string
    value: string
    description: string
}

interface ServiceFormProps {
    serviceOptions: ServiceOption[]
    selectedService: string
    setSelectedService: (value: string) => void
    notes: string
    setNotes: (value: string) => void
    reviewType: string
}

type YesNoOption = {
    label: string
    value: string
}

const YesNoOption: YesNoOption[] = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
]

const ActionButtons = ({ isActive, handleSubmit, handleReject }) => (
    <div className="flex flex-row w-full gap-2 pt-2 mt-auto">
        <Button
            shape="round"
            variant="custom"
            size="sm"
            color="gray-200"
            textColor="text-arsenic"
            className="w-full"
            onClick={handleReject}
        >
            Cancel
        </Button>
        <Button
            shape="round"
            variant="custom"
            size="sm"
            color="trueBlue"
            textColor="text-white"
            className="w-full"
            disabled={isActive}
            onClick={handleSubmit}
        >
            Submit
        </Button>
    </div>
)

const ReviewTab = () => {
    const dispatch = useDispatch()
    const token = useSelector((state: RootState) => state.global?.token)
    const tenantId = useSelector((state: RootState) => state.global?.tenantId)
    const isFirstLoad = useRef(true);

    const [formData, setFormData] = useState<Record<string, any>>({})
    const [isReject, setIsReject] = useState(false)
    const { selectedTable, reviewType, reviewShow, reviewData, selectedDetection, questions } = useSelector(
        (state: RootState) => state.review,
    )

    const handleFormDataChange = (data: any) => {
        setFormData(data)
    }

    function determineStatus(): JSX.Element {
        let status: 'Pending Review' | 'In Progress' | 'Complete' =
            'Pending Review'

        if (selectedTable?.reviewId) {
            status = 'In Progress'
        }
        if (['Approved', 'Rejected'].includes(reviewData?.assetDetail?.status || '')) {
            status = 'Complete'
        }

        return <StatusBadge status={status} size="sm" />
    }

    const isFormDataActive = Object.values(formData).some(
        (value) => value !== '',
    )

    /**
     * Updates the defect's status and sends the updated data to the server.
     *
     * @param {DashboardDefectDto} newValueMap - The updated defect data.
     * @param {string} status - The new status to be set (e.g., 'Approved', 'Rejected').
     * @param rejectList
     */
    async function handleSubmit() {
        const param = {
            url: `/micro-fe/evaluate-defect`,
            method: 'put',
            data: {
                token,
                tenantId,
                id: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.id,
                status: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.status,
                assetId: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.assetId,
                type: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.type,
                componentType: formData.type || reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.componentType,
                priority: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.priority,
                severity: reviewData?.assetDetail?.inspections?.[0]?.defects?.[0]?.severity,
                feedbackList: [],
                others: formData,
            },
        }
        BaseService(param).then((response) => {
            window.location.reload();
        })
    }

    useEffect(() => {
        setFormData({})
    }, [reviewShow])

    const handleReject = () => {
        setIsReject(true)
    }

    /**
     * Transforms feedback data into an array of objects,
     * where each object contains a reason type and reason message.
     *
     * @param {Object} data - An object where keys are reason types and values are arrays of reasons.
     * @returns {Array<Object>} - An array of objects, each with `reasonType` and `reason` properties.
     */
    function transformFeedbackReject(data) {
        const transformedFeedback = []

        Object.entries(data).forEach(([reasonType, reasons]) => {
            reasons.forEach((reason) => {
                transformedFeedback.push({
                    reasonType: reasonType,
                    reason: reason,
                })
            })
        })

        return transformedFeedback
    }

    const sendFeedbackChange = async (feedbackList) => {
        try {
            await BaseService({
                url: '/ml-model/create-change-feedback/',
                method: 'post',
                data: {
                    feedback: {
                        assetId: selectedTable?.assetId,
                        defectId: selectedTable?.defectId,
                    },
                    list: transformFeedbackReject(feedbackList),
                },
            }).then(() => {
                window.location.reload()
            })
        } finally {
            setIsReject(null)
        }
    }

    const handleDetectionClick = (detection) => {
        dispatch(setSelectedDetection(detection))
    }

    useEffect(() => {
        if (isFirstLoad.current && reviewData?.assetDetail?.detections?.length === 1 && !selectedDetection && questions?.length ) {
            dispatch(setSelectedDetection(reviewData.assetDetail.detections[0]));
            isFirstLoad.current = false;
        }
    }, [reviewData?.assetDetail?.detections && questions]);

    useEffect(() => {
        isFirstLoad.current = true;
    }, [selectedTable]);

    return (
        <div className="flex flex-col rounded-lg bg-white border-l border-chineseWhite w-full mt-4 h-full">
            <div className="flex flex-col overflow-y-scroll pt-4 pl-2 h-[calc(100vh-230px)] w-full">
                <div className="flex flex-col">
                    <AIBanner />
                </div>

                {!selectedDetection && reviewData?.assetDetail?.detections?.map((detection) => (<div key={detection?.id}  className="mt-2">
                    <div onClick={() => handleDetectionClick(detection)} className="flex flex-row justify-between border border-chineseWhite p-2 rounded-lg gap-2 mt-2 cursor-pointer items-center">
                        <p> {DETECTION_TYPE_MAPPING[detection?.detectionType] || detection?.detectionType}</p>
                        <StatusBadge status={detection?.status} size="sm" />
                    </div>
                </div>))}
                  {selectedDetection && (
                    <div className="flex flex-col mt-2 h-full">
                        <div className="flex flex-row items-center gap-2 cursor-pointer" onClick={() => dispatch(setSelectedDetection(null))}><MdArrowBack size={20} />Back</div>
                        <div className="h-full">
                            <DetectionForm />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ReviewTab
